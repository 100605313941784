import { Line, mixins } from 'vue-chartjs';
import globalOptionsMixin from "@/components/Charts/globalOptionsMixin";
export default {
  name: 'line-chart',
  extends: Line,
  mixins: [mixins.reactiveProp, globalOptionsMixin],
  props: {
    extraOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handle (e, i) {
      let c = i[0];
     
      let activeLineIndex = this.getElementsAtEvent(e)[0]._datasetIndex;
      alert(activeLineIndex)
      // this.$emit('on-receive', {
      //   label: item._model.datasetLabel,
      //   time: item._model.label,
      // })
    }
  },
  data() {
    return {
      ctx: null
    };
  },
  mounted() {
    this.$watch('chartData', (newVal, oldVal) => {
      // this.extraOptions['onClick']=this.handle;
      // this.extraOptions['responsive']=true;
      // this.extraOptions['maintainAspectRatio']=false;
      this.extraOptions['layout'] = {
        padding : {
          top: 20
        }
      }
      

      this.renderChart(
          this.chartData,
          this.extraOptions
        );

    }, { immediate: true });
  }
};
